import React from "react";

const Menu = () => {
    return (
        <div id={'menu'}>
            <div className="w-full bg-hilite">
                <nav className="flex pl-5 text-lite">
                    <div className="font-lite pr-10"><a href="tel:845-889-7500">Phone: 845.889.7500</a></div>
                    <div><a href="mailto:brian@ithacabroker.com">Email: brian@ithacabroker.com</a></div>
                </nav>
            </div>
        </div>
    )
}

export default Menu;