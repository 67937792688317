import React from "react";
import Menu from "./Menu";

const PaintingsHeader = () => {
    return (
        <div id={'paintings-header'}>
            <div className={'w-full bg-hilite pt-3'}/>
            <div className={'w-full p-14 bg-black'}>
                <h1 className={'text-4xl font-bold text-punch'}>
                    <a href="/">SquaredPaintings</a>
                </h1>
                <p className={'pl-44 text-lite font-thin'}>by Brian DeYoung</p>
            </div>
            <Menu/>
        </div>
    );
}

export default PaintingsHeader;