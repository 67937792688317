import React from "react";

const PaintingsFooter = () => {
    return (
        <div id={'footer'}>
            <div className="w-full bg-hilite pt-3"/>
            <div className={"bg-black flex justify-center"}>
                <p/>
                <p className={"text-sm font-bold text-punch pl-5"}>
                    Unless otherwise noted all artwork is shipped or delivered from Rhode Island
                </p>
                <p/>
            </div>
            <div className="w-full bg-hilite pt-3"/>
        </div>
    )
}

export default PaintingsFooter;