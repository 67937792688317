import './App.css';
import './components/Navbar.js';
import React from 'react';
import "tailwindcss/tailwind.css"

import PaintingsFooter from "./components/PaintingFooter";
import PaintingsHeader from "./components/PaintingHeader";
import Navbar from "./components/Navbar"
import PaintingCard from "./components/PaintingCard"
import {BrowserRouter, Switch, Route} from "react-router-dom";
import Sold from './components/Sold'

class App extends React.Component {
    constructor(props) {
        super(props);
        this.toggleShowSold = this.toggleShowSold.bind(this)
        this.state = {
            sold: true,
            available: true,
            paintings: [],
            soldPaintings: [],
            memoryLanePhotographs: []
        }
    }

    async componentDidMount() {
        const url = "https://yvil0wivdf.execute-api.us-east-2.amazonaws.com/prod/getpaintings";
        const response = await fetch(url);
        const json = await response.json();
        let paintings = JSON.parse(json.body);
        this.setState({ paintings: this.filterUnSold(paintings) });
        this.setState({ soldPaintings: this.filterSold(paintings)} );

        const memoryLaneUrl = "https://yvil0wivdf.execute-api.us-east-2.amazonaws.com/prod/sp-react-get-photographs";
        const memoryLaneResponse = await fetch(memoryLaneUrl);
        const memoryLaneJson = await memoryLaneResponse.json();
        let memoryLanePhotographs = JSON.parse(memoryLaneJson.body);
        this.setState({memoryLanePhotographs: memoryLanePhotographs});
    }

    toggleShowSold() {
        console.log(this.state)
        this.setState({sold: !this.state.sold})
    }

    filterSold(paintings) {
        return paintings.filter(p => {
            return p.available === false
        })
    }

    filterUnSold(paintings) {
        return paintings.filter(p => {
            return p.available === true
        })
    }

    getPrice(price) {
        return price;
        // return price * 1.2;
    }

    render() {
        return (
            <div>

                <BrowserRouter>
                    <PaintingsHeader/>
                    <Navbar/>
                    <div className={'mainWrapper'}>

                        <div className={'pb-10'}/>
                        <Switch>
                            <Route path={"/sold"} component={Sold}>
                                {
                                    this.state.soldPaintings.map((painting) => (
                                        <PaintingCard
                                            width={painting.width}
                                            name={painting.name}
                                            material={painting.material}
                                            desc={painting.desc}
                                            available={painting.available}
                                            price={this.getPrice(painting.price)}
                                            imgsrc={painting.src}
                                        />
                                    ))
                                }
                            </Route>
                            <Route path={"/"} exact>
                                <div className="App">
                                    {
                                        this.state.paintings.map((painting) => (
                                            <PaintingCard
                                                width={painting.width}
                                                name={painting.name}
                                                material={painting.material}
                                                desc={painting.desc}
                                                available={painting.available}
                                                price={this.getPrice(painting.price)}
                                                imgsrc={painting.src}
                                            />
                                        ))
                                    }
                                </div>
                            </Route>
                            <Route path={"/memoryLane"} exact>
                                <div className="App">
                                    {
                                        this.state.memoryLanePhotographs.map((photograph) => (
                                            <PaintingCard
                                                width={photograph.width}
                                                name={photograph.name}
                                                material={photograph.material}
                                                desc={photograph.desc}
                                                available={photograph.available}
                                                price={this.getPrice(photograph.price)}
                                                imgsrc={photograph.src}
                                            />
                                        ))
                                    }
                                </div>
                            </Route>
                        </Switch>
                    </div>
                    <PaintingsFooter/>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
