import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    root: {
        minWidth: 500,
        marginBottom: 50
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const getPaintingWidth = (inches) => {
    return inches * 50
}

const getImgPath = (src) => {
    return 'assets/' + src;
}

const getForSaleText = (available) => {
    return available ? "For Sale" : "Sold!"
}

const getPaintingsPrice = (price, available) => {
    return available ? "Call for pricing" : "" // price ? "$" + price : ""
}

export default function SimpleCard({key, name, desc, price, imgsrc, width, available, material}) {
    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;

    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography variant="h4" component="h2" className={'text-left text-3xl font-bold text-gray'}>
                    {name}
                </Typography>
                <img src={getImgPath(imgsrc)}
                     width={getPaintingWidth(width)}
                     alt={name}
                     className={"imgMax"}
                />
                <Typography variant={"h5"}  gutterBottom>
                    <div className={'text-left font-bold text-gray pt-3'}>
                        {name}
                    </div>
                    <div className={'text-left font-bold text-gray'}>
                        {material} {width}"
                    </div>
                </Typography>
                <Typography variant={"p"}>
                    <p className={'font-medium text-left text-black'}>
                       {desc}
                    </p>
                </Typography>
                <Typography variant={"p"}>
                    <div className={'font-medium text-black text-left'}>
                        <p className={'text-left font-lg text-black font-bold'}>{getForSaleText(available)}</p>
                    </div>
                </Typography>
                <Typography variant="body2" component="p">
                    <div className={'font-medium text-black text-left'}>
                        <p className={'text-left font-lg text-black font-bold'}>{getPaintingsPrice(price, available)}</p>
                    </div>
                </Typography>
            </CardContent>
            {/*<CardActions>*/}
            {/*    <Button size="small">Learn More</Button>*/}
            {/*</CardActions>*/}
        </Card>
    );
}
