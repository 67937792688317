import React from "react";

class Sold extends React.Component {
    render() {
        return (
            <div>
                <h1>Sold</h1>
            </div>

        )
    }
}

export default Sold;